<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VueContentLoading } from "vue-content-loading";
import Binary from "@/components/widgets/binary-user";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Left Team': 'Equipe Esquerda',
      'Right Team': 'Equipe Direita',
      'CURRENT': 'ATUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPE',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',

      'Username': 'Usuário',
      'Username is required.': 'O usuário é obrigatório.',
      'Search': 'Buscar',
      'The username was not found.': 'O usuário não foi encontrado.',
    },
    es: {
      'Left Team': 'Equipe Izquierda',
      'Right Team': 'Equipe Derecha',
      'CURRENT': 'ACTUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPO',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',

      'Username': 'Usuário',
      'Username is required.': 'Nombre de usuario es requerido.',
      'Search': 'Buscar',
      'The username was not found.': 'No se encontró el nombre de usuario.',
    }
  },
  components: { Layout, VueContentLoading, Binary },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,

      id: this.$route.params.id,
      username: null,
      name: null,
      image: null,

      left: {
        current: '0',
        total: '0',
        users: '0',
      },
      right: {
        current: '0',
        total: '0',
        users: '0',
      },

      tree: {
        left: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        },
        right: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        }
      },

      upline: {
        left: 0,
        right: 0,
      },

      final: {
        left: 0,
        right: 0,
      },

      alert: { type: '', message: '' },
      search: {
        loading: false,
        username: '',
      },
    }
  },
  validations: {
    search: {
      username: { required }
    },
  },
  methods: {
    getBinary() {
      if (this.id) {
        api
          .get('network/binary/'+this.id)
          .then(response => {
            if (response.data.status=='success') {
              this.left.current = response.data.points.current.left
              this.left.total = response.data.points.total.left
              this.left.users = response.data.users.left

              this.right.current = response.data.points.current.right
              this.right.total = response.data.points.total.right
              this.right.users = response.data.users.right

              this.username = response.data.user.username
              this.name = response.data.user.name
              this.image = response.data.user.image

              this.tree = response.data.tree
              this.upline = response.data.upline
              this.final = response.data.final
            }
          })
          .catch(error => {
            this.errored = error
            this.loading = false
          })
          .finally(() => {
            this.errored = false
            this.loading = false
          })
      }
    },
    setBinary(id) {
      this.id = id
      this.loading = true
      this.getBinary()
    },
    searchUser() {
      this.$v.$touch();

      if (this.search.username) {
        this.search.loading = true

        api
          .post('network/binary/search', {
            username: this.search.username,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.search.username = ''
              this.$v.$reset()

              this.alert.type = ''
              this.alert.message = ''

              this.$router.push('/network/binary/'+response.data.user.id);
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
            }

            this.search.loading = false
          })
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.id = this.account.user.id
    }

    this.getBinary();
  },
  watch: {
    '$route.params.id': function (id) {
      if (id) {
        this.loading = true
        this.id = id
      } else {
        this.id = this.account.user.id
      }

      this.getBinary()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Binário') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <b-form class="p-3" @submit.prevent="searchUser">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <b-form-group id="search" :label="t('Username')" label-for="search">
                <b-form-input id="search" v-model="search.username" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.search.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                  autocomplete="off"></b-form-input>
                <div v-if="$v.search.username.$error" class="invalid-feedback">
                  <span v-if="!$v.search.username.required">{{ t('Username is required.') }}</span>
                </div>
              </b-form-group>
              <b-button :disabled="search.loading == true || search.username===''" type="submit" variant="default">
                {{ t('Search') }}
                <b-spinner v-if="search.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <table class="table table-centered table-nowrap text-center">
              <thead>
                <tr>
                  <th class="border-top-0"></th>
                  <th class="border-top-0">{{ t('Left Team') }}</th>
                  <th class="border-top-0">{{ t('Right Team') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ t('CURRENT') }}</td>
                  <td class="notranslate">{{ left.current }}</td>
                  <td class="notranslate">{{ right.current }}</td>
                </tr>
                <tr>
                  <td>{{ t('TOTAL') }}</td>
                  <td class="notranslate">{{ left.total }}</td>
                  <td class="notranslate">{{ right.total }}</td>
                </tr>
                <tr>
                  <td>{{ t('TEAM') }}</td>
                  <td class="notranslate">{{ left.users }}</td>
                  <td class="notranslate">{{ right.users }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table-responsive">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else id="binary" class="table-responsive">
              <table id="tree" class="table" style="margin:0;">
                <tr class="a1">
                  <td colspan="11">
                    <router-link :to="'/network/binario/'+final.left" class="btn btn-default mr-2">FINAL</router-link>
                    <router-link :to="'/network/binario/'+upline.left" class="btn btn-default">UP</router-link>
                  </td>
                  <td colspan="10" class="team-item">
                    <div v-if="loading"><template><vue-content-loading :width="20" :height="20">
                          <circle cx="10" cy="10" r="10" />
                        </vue-content-loading></template></div>
                    <div v-else-if="image"><img :src="require('@/assets/images/plans/' + image)"><span>{{ username }}</span></div>
                    <div v-else><img src="@/assets/images/logo-icon-app.png"><span>{{ username }}</span></div>
                  </td>
                  <td colspan="11">
                    <router-link :to="'/network/binario/'+upline.right" class="btn btn-default mr-2">UP</router-link>
                    <router-link :to="'/network/binario/'+final.right" class="btn btn-default">FINAL</router-link>
                  </td>
                </tr>
                <tr class="a2">
                  <td colspan="8"></td>
                  <td colspan="8" class="aleft"></td>
                  <td colspan="8" class="aright"></td>
                  <td colspan="8"></td>
                </tr>
                <tr class="a2">
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                </tr>
                <tr>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                </tr>
                <tr class="a1">
                  <td colspan="16" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c1.l1" />
                  </td>
                  <td colspan="16" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c1.l1" />
                  </td>
                </tr>
                <tr class="a3">
                  <td colspan="4"></td>
                  <td colspan="4" class="aleft"></td>
                  <td colspan="4" class="aright"></td>
                  <td colspan="4"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="aleft"></td>
                  <td colspan="4" class="aright"></td>
                  <td colspan="4"></td>
                </tr>
                <tr class="a3">
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                </tr>
                <tr class="a2">
                  <td colspan="8" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c2.l1" />
                  </td>
                  <td colspan="8" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c2.l2" />
                  </td>
                  <td colspan="8" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c2.l1" />
                  </td>
                  <td colspan="8" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c2.l2" />
                  </td>
                </tr>
                <tr class="a4">
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                </tr>
                <tr class="a4">
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                </tr>
                <tr class="a3">
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c3.l1" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c3.l2" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c3.l3" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c3.l4" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c3.l1" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c3.l2" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c3.l3" />
                  </td>
                  <td colspan="4" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c3.l4" />
                  </td>
                </tr>
                <tr class="a5">
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                </tr>
                <tr class="a5">
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                </tr>
                <tr class="a4">
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l1" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l2" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l3" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l4" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l5" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l6" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l7" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.left.c4.l8" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l1" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l2" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l3" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l4" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l5" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l6" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l7" />
                  </td>
                  <td colspan="2" class="team-item">
                    <Binary :loading="loading" :tree="tree.right.c4.l8" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>